#root {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.outer-app-wrapper {
  height: 100%;
}
.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ccp-inner-wrapper {
  background-color: #eee;
  overflow: hidden;
  width: 450px;
  height: 700px;
  max-width: 450px;
  max-height: 100%;
  box-sizing: border-box;
  box-shadow: 0px 0px 2px 2px #e2e2e2;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.ccp-inner-wrapper.ccp-error {
  padding: 24px;
}

#ccp {
  height: 100%;
}
#ccp iframe {
  border: none;
  display: none;
}

.ccp-inner-wrapper button {
  display: none;
  padding: 8px 16px;
  font-weight: 600;
  margin-top: 24px;
  cursor: pointer;
  background-color: #e2e2e2;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.4);
}
.ccp-inner-wrapper button:hover {
  background-color: #e9e9e9;
}
.ccp-inner-wrapper button:active {
  background-color: #e5e5e5;
}
.ccp-inner-wrapper:not(.ccp-ready) button.visible {
  display: initial;
}

.ccp-inner-wrapper.ccp-ready #ccp iframe {
  display: block;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}
.App main {
  display: flex;
  flex-direction: row;
  flex: 2;
  overflow: hidden;
}
.info {
  grid-column: 1 / span 2;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-right: 10px;
}

div.call-history {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 10px;
  overflow: hidden;
  align-items: center;
}
div.call-history ul {
  overflow: auto;
  min-width: 400px;
  max-width: 600px;
  padding-right: 4px;
}

li.call-log-item {
  list-style-type: none;
  border-radius: 8px;
  margin: 4px;
  margin-bottom: 4px;
  padding: 8px;
  background-color: #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

li.call-log-item:hover {
  background-color: #f4f4f4;
}
li.call-log-item.current {
  border: 2px solid #0b3c6f;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
}

li.call-log-item .icon .inbound {
  color: #009;
}
li.call-log-item .icon .outbound {
  color: #090;
}

li.call-log-item .details {
  flex: 2;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0px 20px;
}

li.call-log-item .actions {
  color: #555;
}
li.call-log-item .actions span {
  margin: 0px 4px;
  cursor: pointer;
}
li.call-log-item .actions span.call:hover {
  color: #090;
}
li.call-log-item .actions span.copy:hover {
  color: #009;
}

li.call-log-item .details .header .caller-id {
  font-weight: bold;
}

.ccp-outer-wrapper {
  flex: 1;
  grid-column: 3 / span 1;
  max-width: 450px;
  min-width: 450px;
}

dialog.modal .modal-container {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  border: none;
  padding: 0px;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
dialog.modal .modal-info {
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.2);
  --text-align: center;
  background-color: #fff;
  width: 600px;
  --padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
}

dialog.modal .modal-info .title {
  background-color: #0b3c6f;
  color: #fff;
  width: 100%;
  padding: 4px;
  font-weight: 500;
  box-sizing: border-box;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: center;
}

dialog.modal .modal-info .message {
  margin: 8px 16px;
}

dialog.modal .modal-info button {
  width: 80px;
  padding: 12px;
  margin-top: 12px;
  cursor: pointer;
}

dialog.modal .modal-info details {
  width: 100%;
}

dialog.modal .modal-info details summary {
  cursor: pointer;
  text-align: left;
  margin: 0px 16px 16px 16px;
}

dialog.modal .modal-info details div {
  text-align: left;
  max-height: 200px;
  overflow: auto;
  font-family: monospace;
  margin: 16px 32px;
  background-color: #eee;
  padding: 8px;
  border: 2px groove #ccc;
}
